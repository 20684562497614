import React from 'react';
import styles from '../Home.module.css';
import Resume from '../../../assets/images/Misc/bit8resume.jpeg';
import PDFResume from '../../../assets/documents/resume.pdf';
import Linkedin from '../../../assets/images/Misc/8bitlinkedin.png';
import Github from '../../../assets/images/Misc/8bit-github.png';

const Contact = (props) => {
  const { title } = props;
  return (
    <div className={styles.contactContainer}>
      <h2 className={styles.title}>{title}</h2>


      <div className={styles.contactButtonsSection}>
        <button className={styles.button}>
          <a className={styles.anchorTag} href={PDFResume} rel="noopener noreferrer" target="_blank">
            <img src={Resume} className={`${styles.icon} ${styles.resumeIcon}`} />
            <p className={`${styles.buttonText} ${styles.contactTextColor}`}>Resume</p>
          </a>
        </button>
        <button className={styles.button}>
          < a className={styles.anchorTag} href="https://github.com/james2kim" rel="noopener noreferrer" target="_blank">
            <img src={Github} className={styles.icon} />
            <p className={`${styles.buttonText} ${styles.contactTextColor}`}>Github</p>
          </a>
        </button>
        <button className={styles.button}>
          <a className={styles.anchorTag} href="https://www.linkedin.com/in/james2kim" rel="noopener noreferrer" target="_blank">
            <img src={Linkedin} className={styles.icon} />
            <p className={`${styles.buttonText} ${styles.contactTextColor}`}>Linkedin</p>
          </a>
        </button>
      </div>


    </div>
  )
}

export default Contact;