import React, { useState, useEffect } from 'react';
import styles from "./Home.module.css";
import disk from "../../assets/images/Misc/floppydisk.png";
import mail from "../../assets/images/Misc/8bitmail.png";
import man from "../../assets/images/Misc/man.svg";
import close from "../../assets/images/Misc/close.svg";
import About from "./About/About.jsx";
import Experience from "./Experience/Experience.jsx";
import Contact from "./Contact/Contact.jsx";
import ProfilePhoto from "../../UI/ProfilePhoto/ProfilePhoto";
const pagesDictionary = {
  home: "",
  about: "about",
  experience: "experience",
  contact: "Let's Get in Touch!"
}

const Home = (props) => {
  const [pageIndicator, setPageIndicator] = useState(pagesDictionary.home)
  const [showPicture, setShowPicture] = useState(true)

  useEffect(() => {
    const maxMobileScreenWidth = 480
    if (window.screen.width < maxMobileScreenWidth) {
      setShowPicture(false)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div />
      <div />
      <div />
      <div />
      <div className={styles.background}>
        {pageIndicator === pagesDictionary.about && showPicture && (
          <div className={styles.profilePhoto}>
            <ProfilePhoto />
          </div>
        )}
        <div className={styles.homeContent}>
          <div className={styles.centerContainer}>
            {pageIndicator !== pagesDictionary.home && (
              <button className={styles.closeButton} onClick={() => setPageIndicator(pagesDictionary.home)}>
                X
              </button>
            )}
            {pageIndicator === pagesDictionary.home && (
              <div className={styles.home}>
                <div className={styles.homeButtonsSection}>
                  <button className={`${styles.button} ${styles.animate}`} onClick={() => setPageIndicator(pagesDictionary.about)}>
                    <img src={man} className={styles.icon} />
                    <p className={styles.buttonText}>ABOUT</p>
                  </button>
                  <button className={`${styles.button} ${styles.animate}`} onClick={() => setPageIndicator(pagesDictionary.experience)}>
                    <img src={disk} className={styles.icon} />
                    <p className={styles.buttonText}>EXPERIENCE</p>
                  </button>
                  <button className={`${styles.button} ${styles.animate}`} onClick={() => setPageIndicator(pagesDictionary.contact)}>
                    <img src={mail} className={styles.icon} />
                    <p className={styles.buttonText}>CONTACT</p>
                  </button>
                </div>
              </div>
            )}
            {pageIndicator === pagesDictionary.about && (
              <About title={pageIndicator} />
            )}
            {pageIndicator === pagesDictionary.experience && (
              <Experience title={pageIndicator} />
            )}
            {pageIndicator === pagesDictionary.contact && (
              <Contact title={pageIndicator} />
            )}
          </div>
        </div>
      </div>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default Home;