import React from 'react'
import styles from './Landing.module.css'
import { pagesDictionary } from "../../App";

const Home = (props) => {
    return (
        <div className={styles.intro}>
            <div className={styles.titleBlock}>
                <h1 className={styles.job}>Software Engineer</h1>
                <p className={styles.name}>By James2Kim</p>
            </div>
            <button className={styles.startButton} onClick={() => props.setSelectedComponent(pagesDictionary.home.name)}>{`> Press Start`}</button>

        </div>
    )
}
export default Home;