import React from 'react';
import styles from '../Home.module.css';
import ProfilePhoto from "../../../UI/ProfilePhoto/ProfilePhoto";

const About = (props) => {
  const { title } = props;
  return (
    <div className={styles.contentContainer}>
      <div className={styles.about}>
        <h2 className={styles.title}>{title}</h2>
        {/* <ProfilePhoto /> */}
        <p className={styles.text}>Hi, my name is James, and I'm a software engineer who
        strives to build highly functional and scalable applications.
        I enjoy taking an idea and turning it
        into a beautiful, performant software built via clean, reusable, and flawlessly designed code.
        I am well versed in React.js, Ionic Framework, and Typescript
               when developing, but I always strive to learn new technologies and evolve my existing skillsets. </p>
        <p className={styles.text}>Currently, I'm working as Software Engineer for Axiom Law, where I contributed to the development of revenue driving client solutions such ass Talent Finder, Opportunity Feed, and Interview Automation.</p>
        <p className={styles.text}> In addition to my personal projects and career development, I take the time to contribute to the online developer community.
        I have a Youtube series that teaches other coders how to understand and solve
        algorithms and coding challenges. </p>
      </div>
    </div>
  )
}

export default About;