import React from 'react';
import styles from './Container.module.css';

const Container = (props) => {
  const { Component } = props
  return (
    <div className={styles.background}>
      <div className={styles.flickerOverlay}>
        {Component}
      </div>
    </div>
  )
}

export default Container;
