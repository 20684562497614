import React, { useState } from 'react';
import Landing from './Components/Landing/Landing.jsx';
import Home from './Components/Home/Home.jsx';
import Container from "./HOC/Container";

export const pagesDictionary = {
  landing: {
    name: "Landing",
    Component: Landing,
  },
  home: {
    name: "Home",
    Component: Home,
  },
}

const App = () => {
  const [selectedComponent, setSelectedComponent] = useState(pagesDictionary.landing.name)
  const RenderedComponent = selectedComponent === pagesDictionary.landing.name ? Landing : Home;
  return (
    <Container Component={<RenderedComponent setSelectedComponent={setSelectedComponent} />} />
  );
}

export default App;
