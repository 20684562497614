import React from 'react';
import styles from '../Home.module.css';

const Experience = (props) => {
  const { title } = props;

  return (
    <div className={styles.contentContainer}>
      <div className={styles.about}>
        <h2 className={styles.title}>{title}</h2>
        <div>
          <div className={styles.jobTitle}>
            <span className={styles.text}>Software Engineer III @ Axiom Law</span>
            <span className={styles.text}>2022 - Present</span>
          </div>
          <ul className={`${styles.list} ${styles.text}`}>
            <li>Engineered a HubSpot integrated form collection widget and a virtualized talent feed, which resulted in a 20% increase in self-served client actions on the platform and a 15% increase in new net revenue for the business.</li>
            <li>Architected the front-end workflow and and Calendar component for interview automation functionality.  </li>
            <li>Generated automated unit and component tests using Jest and React Testing Library for developers to minimize unexpected application bugs by evaluating critical edge cases and preserving code quality for future updates. </li>
            <li>Utilized React Router and Suspense to navigate between UI pages without page refreshes in order to minimize requests, improve loading indications, and maintain application state for a single page application. </li>
            <li>Conducted comprehensive code reviews to uphold development and testing standards, rectify existing bugs, and optimize software performance.  </li>
          </ul>
        </div>
        <div>
          <div className={styles.jobTitle}>
            <span className={styles.text}>Front End Engineer @ Fresh Consulting</span>
            <span className={styles.text}>2021 - 2022</span>
          </div>
          <ol className={`${styles.list} ${styles.text}`}>
            <div>Relavent Projects</div>
            <li>
              <p>Team Nation Sports</p>
              <ul>
                <li>Serve as a mobile UI colead in building a full stack mobile and web sports learning application.</li>
                <li>Utilized Ionic 5 and Cordova to seamlessly integrate custom React components as mobile UI.</li>
                <li>Engineered scalable and interactive mobile gaming experiences for end users using React, Ionic, Keyframe Animations. These games are being played and enjoyed by thousands of players daily.</li>
                <li>Work directly with the tech lead to architect front end features, integrate microservices, and assist back-end API design.</li>
                <li>Utilized Android Studio/Xcode to create, simulate, and test native builds of the mobile app.</li>
                <li>Generated automated unit and functional tests using Jest for developers to minimize unexpected application bugs by evaluating critical edge cases and preserving code quality for future updates.</li>
              </ul>
            </li>
            <li>
              <p>Committee on Foreign Investments of the United States</p>
              <ul>
                <li>Built a case management single page application that utilizes React for its top-down information state management, flexibility of JSX, and tree reconciliation based on the virtual DOM to expedite the UI development of an SPA.</li>
                <li>Utilized Typescript to enforce strict typing of props and state in order to minimize unexpected bugs and to improve code quality by ensuring self-documenting code. </li>
                <li>Refactored codebase to address 508 accessibility concerns, that were compliant to ADA standards.</li>
                <li>Swiftly responded to feature/bug requests, refactored the codebase, and managed client expectations.</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )

}

export default Experience;